
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import { getStoryblokApi } from '@storyblok/react'
import { GetStaticProps, NextPage } from 'next'
import { NextSeo } from 'next-seo'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import { useEffect } from 'react'
import styled from 'styled-components'
import Layout from '@/app/common/components/Layout'
import config from '@/config'
import * as tracking from '@/helpers/tracking'
import { media } from '@/style/helpers'
import { ERegionCode } from '@/types/gql/graphql'

const NotFoundPage: NextPage = () => {
  const { t } = useTranslation()
  useEffect(() => {
    tracking.trackEvent('page_not_found')
  }, [])

  return (
    <Layout>
      <NextSeo noindex />
      <MainWrapper>
        <NotFound>
          <NotFoundTitle>404</NotFoundTitle>
          <NotFoundSubTitle>{t('notFound:title')}</NotFoundSubTitle>
          <NotFoundWrapperAction>
            <ButtonLink href="/">{t('notFound:anchorLinkHome')}</ButtonLink>
          </NotFoundWrapperAction>
        </NotFound>
      </MainWrapper>
    </Layout>
  )
}

// TODO: handle locales & regions, for now this is hardcoded (not optimal)
 const _getStaticProps: GetStaticProps = async () => {
  const configStory = await getStoryblokApi().getStory(
    config.isStoryblokDevSpace ? 'config' : 'at/config',
    {
      version: 'published',
    },
  )

  return {
    props: {
      config: configStory.data.story.content,
      regionCode: ERegionCode.At,
    },
  }
}

export default NotFoundPage

const MainWrapper = styled.div`
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  text-align: center;
  background: radial-gradient(
    circle,
    rgba(195, 215, 240, 1) 0%,
    rgba(120, 128, 191, 1) 27%,
    rgba(68, 68, 158, 1) 100%
  );

  display: flex;
  justify-content: center;
  align-items: center;
`

const NotFound = styled.div`
  width: 100%;
  max-width: 640px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
`

const NotFoundTitle = styled.h1`
  font-family: 'GalanoGrotesque';
  font-weight: bold;
  font-size: 140px;
  line-height: 152px;

  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  text-shadow: 0px 4px 34px rgba(0, 0, 0, 0.25);
  padding: 0 40px;

  ${media.md} {
    font-size: 190px;
    line-height: 206px;
  }
`

const NotFoundSubTitle = styled.h3`
  font-family: 'GalanoGrotesque';
  font-weight: bold;
  font-size: 36px;
  line-height: 41px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  padding: 0 40px;

  ${media.md} {
    font-size: 42px;
    line-height: 48px;
  }
`

const NotFoundWrapperAction = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 40px;
`

const ButtonLink = styled(Link)`
  display: flex;
  justify-content: center;

  padding: 11px 20px;
  gap: 10px;
  min-width: 155px;
  height: 44px;
  background: #000000;
  border-radius: 5px;

  font-family: 'GalanoGrotesque';
  font-weight: semiBold;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  text-decoration: none;
`


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        let res = _getStaticProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/404',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  